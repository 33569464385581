<template>
  <review-card
    :key="`list-group-chackra-${expanded}`"
    :title="`${
      hideNamesChakras ? 'Centros de distribuição energéticos' : 'Chakras'
    }`"
    :explanation="explanation"
  >
    <v-list-group
      v-for="(item, index) in chakras"
      :key="index"
      class="item-par"
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item-title v-if="!item.hidePatient">
          {{ item.chakra.name }}
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ item.chakra.friendlyName }}
        </v-list-item-title>
      </template>
      <div class="pb-0 grey--text text--darken-3">
        <image-section :image="item.chakra.image" />
        <property-value
          property="Atuações"
          :value="item.procedures | arrayToString"
        />

        <property-value
          property="Impactar em casa"
          :value="item.patientImpactAtHome | booleanToString"
        />
        <property-value property="Comentários" :value="item.patientComments" />
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "Chakras",
  props: {
    hideNamesChakras: {
      type: Boolean,
      default: false,
    },
    chakras: {
      type: Array,
      default: () => [],
    },
    explanation: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
